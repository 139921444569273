<template>
  <div class="grid grid-cols-1 md:grid-cols-2">
    <!-- landing 1 -->
    <div class="bg-agrogo-yellow-2">
      <div class="bg-agrogo-landing grid grid-cols-1">
        <div class="flex items-center justify-center py-5">
          <img
            class="w-1/2 md:w-full md:max-h-10 lg:max-h-16"
            src="@/assets/images/agrogofincasdark.svg"
            alt=""
          />
        </div>
        <div class="flex items-center">
          <div class="grid grid-cols-1 w-3/5 place-items-end">
            <div class="relative h-auto">
              <div
                class="bg-agrogo-green-11 h-5 lg:h-8 absolute position mr-4 w-48 lg:w-64 xl:w-72"
              ></div>
              <div
                class="font-dinpro font-bold text-white text-2xl text-right pr-4 lg:text-3xl xl:text-4xl"
              >
                1er Market Place
              </div>
            </div>
            <div
              class="bg-green-transparent ml-7 py-3 px-4 md:py-5 lg:px-10 xl:px-20"
            >
              <p
                class="font-dinpro font-bold text-white text-base lg:text-xl xl:text-2xl leading-1 text-center"
              >
                Especializado en <br />
                <span class="text-agrogo-yellow-2 leading-0"
                  >Venta & Compra <br
                /></span>
                de Fincas de Trabajo
              </p>
            </div>
          </div>
          <div class="flex justify-start w-2/5">
            <img
              class="telephone-width lg:pr-4"
              src="@/assets/images/telephonelandingfarm.png"
              alt=""
            />
          </div>
        </div>
        <div class="flex flex-col items-center justify-center py-4">
          <button class="btn space-x-2">
            <span
              class="font-dinpro font-bold text-agrogo-green-2 text-xl md:text-2xl"
              >INGRESA AQUÍ</span
            >
            <div
              class="rounded-full h-6 w-6 md:h-7 md:w-7 bg-agrogo-green-2 flex items-center justify-center"
            >
              <span class="text-white text-sm icon-rocket"></span>
            </div>
          </button>
          <div
            class="font-dinpro font-medium text-sm leading-none hidden md:flex"
          >
            none
          </div>
        </div>
      </div>
    </div>
    <div
      class="md:hidden h-5 bg-agrogo-green-2 flex items-center justify-start pl-2"
    >
      <span class="text-white font-dinpro font-bold text-xs"
        ><span class="text-agrogo-yellow-1">#ELCAMPOEN</span>UNSOLOLUGAR</span
      >
      <div class="bg-agrogo-yellow-2 h-line w-full ml-4"></div>
    </div>
    <!-- landing 2 -->
    <div class="bg-agrogo-green-11">
      <div class="bg-agrogo-landing grid grid-cols-1">
        <div class="flex items-center justify-center py-6">
          <img
            class="w-1/2 md:w-full md:max-h-10 lg:max-h-16"
            src="@/assets/images/agrogosubastasdark.svg"
            alt=""
          />
        </div>
        <div class="flex items-center">
          <div class="flex justify-end w-2/5">
            <img
              class="telephone-width lg:pl-4"
              src="@/assets/images/telephonelandingauction.png"
              alt=""
            />
          </div>
          <div class="grid grid-cols-1 w-3/5 place-items-start">
            <div class="h-auto bg-agrogo-green-2 w-44 lg:w-56 xl:w-80">
              <div
                class="font-dinpro font-bold text-white text-2xl text-left pl-2 lg:text-3xl xl:text-4xl"
              >
                Próximamente
              </div>
            </div>
            <div class="bg-green-2-transparent p-2 lg:py-3 xl:px-8">
              <p
                class="font-dinpro font-medium text-white text-sm leading-1 text-center lg:text-xl xl:text-2xl"
              >
                Espera el
                <span class="text-agrogo-yellow-1"> Lanzamiento</span> de <br />

                nuestra
                <span class="text-agrogo-yellow-1">nueva App </span> que<br />
                Revolucionará la manera de <br />
                hacer
                <span class="text-agrogo-yellow-1"> Negocios de Animales </span>
              </p>
            </div>
          </div>
        </div>
        <div class="flex flex-col items-center justify-center py-4">
          <button class="btn space-x-2">
            <span
              class="font-dinpro font-bold text-agrogo-green-2 text-xl md:text-2xl"
              >REGISTRATE AQUÍ</span
            >
            <div
              class="rounded-full h-6 w-6 bg-agrogo-green-2 flex items-center justify-center"
            >
              <span class="text-white text-sm icon-rocket"></span>
            </div>
          </button>
          <div
            class="font-dinpro font-medium text-agrogo-gray-1 text-sm leading-none hidden md:flex"
          >
            Para Beneficios de Prelanzamiento
          </div>
        </div>
        <div
          class="bg-agrogo-green-11 font-dinpro font-normal text-agrogo-gray-1 text-xs leading-none md:hidden"
        >
          Para Beneficios de Prelanzamiento
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.telephone-width {
  width: 7.375rem;
}
.bg-green-transparent {
  --tw-bg-opacity: 0.6 !important;
  background-color: rgba(21, 62, 53, var(--tw-bg-opacity)) !important;
}
.bg-green-2-transparent {
  --tw-bg-opacity: 0.8 !important;
  background-color: rgba(113, 152, 74, var(--tw-bg-opacity)) !important;
}
.leading-1 {
  line-height: 1rem !important;
}
.btn {
  width: auto;
  height: 1.875rem;
  background-color: #f5ce3e;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media (min-width: 768px) {
  .btn {
    height: 2.625rem;
  }
  .telephone-width {
    width: 8rem;
  }
}
@media (min-width: 1024px) {
  .btn {
    height: 2.625rem;
  }
  .telephone-width {
    width: 12.5rem;
  }
  .leading-1 {
    line-height: 1.25rem !important;
  }
}
.position {
  top: 1.2rem;
  right: 0;
}
.h-line {
  height: 0.0836rem;
}
</style>
